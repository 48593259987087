import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Outlet, useMatches, useNavigate } from 'react-router-dom';
import { useApplicationContext } from './ApplicationContext';
import { Layout } from './Layout';
import { useEffect, useState } from 'react';

const ProtectedRoute = () => {
    const { currentUser } = useApplicationContext();
    const matches = useMatches();
    const navigate = useNavigate();
    const [allowed, setAllowed] = useState(true);
    const [loading, setLoading] = useState(true);
    const [requiredPermissions, setRequiredPermissions] = useState(new Set());

    useEffect(() => {
        setRequiredPermissions(new Set());
        let pagePermissions = matches.filter((match) => Boolean((match.handle as any)?.permission));
        if (pagePermissions.length === 0) {
            setLoading(false);
            setAllowed(true);
        } else if (currentUser && Object.keys(currentUser).length > 0) {
            pagePermissions.forEach((e) => {
                if (
                    !currentUser.allPermissions?.some((permission) => {
                        if ((e.handle as any).permission instanceof Array) {
                            return (e.handle as any).permission?.some((p) => permission.permissionName === p);
                        } else {
                            return permission.permissionName === (e.handle as any).permission;
                        }
                    })
                ) {
                    setAllowed(false);
                    setRequiredPermissions((prev) => prev.add((e.handle as any).permission));
                } else {
                    setAllowed(true);
                }
            });
            setLoading(false);
        }
    }, [currentUser, matches, setAllowed, setRequiredPermissions, setLoading]);

    useEffect(() => {
        if (!loading && !allowed) {
            alert('The following permissions are required to access this page: ' + [...requiredPermissions].join(', '));
            navigate(-1);
        }
    }, [allowed, navigate, requiredPermissions, loading]);

    return <Layout>{loading || !allowed ? null : <Outlet />}</Layout>;
};

export default withAuthenticationRequired(ProtectedRoute);
