import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import Auth0ProviderWithHistory from './Components/Auth0ProviderWithHistory';
import CategorizationName from './Components/Categorization/CategorizationName';
import GLCodeSetName from './Components/GLCodeSets/GLCodeSetName';
import ReportName from './Components/ReportName';
import { AnalyticsProvider } from './contexts/AnalyticsContext';
import { ReportProvider } from './contexts/ReportContext';
import ProtectedRoute from './ProtectedRoute';
import { BreadCrumbItem } from './Components/BreadCrumbs';
import loadable from '@loadable/component';
import './App.css';
import CommunityPage from './Components/Admin/Communities/CommunityPage';
import CommunityName from './Components/Admin/Communities/CommunityName';

const Admin = loadable(() => import('./Pages/Admin'));
const Budgets = loadable(() => import('./Pages/Budgets'));
const BudgetVersionEditor = loadable(() => import('./Components/Budget/BudgetVersionEditor/BudgetVersionEditor'));
const Categories = loadable(() => import('./Components/Categorization/Categories'));
const CategorizationMapping = loadable(() => import('./Components/Categorization/CategorizationMapping'));
const Categorization = loadable(() => import('./Pages/Categorization'));
const CompanyReportConfig = loadable(() => import('./Pages/CompanyReportConfig'));
const Communities = loadable(() => import('./Pages/Communities'));
const Configuration = loadable(() => import('./Pages/Configuration'));
const GLCodeSet = loadable(() => import('./Components/GLCodeSets/GLCodeSet'));
const GLCodeSets = loadable(() => import('./Pages/GLCodeSets'));
const Home = loadable(() => import('./Pages/Home'));
const Report = loadable(() => import('./Components/Report'));
const ReportConfig = loadable(() => import('./Pages/ReportConfig'));
const Reports = loadable(() => import('./Pages/Reports'));
const SLAdmin = loadable(() => import('./Pages/SLAdmin'));
const Subscriptions = loadable(() => import('./Pages/Subscriptions'));
const UserConfig = loadable(() => import('./Pages/UserConfig'));

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<Auth0ProviderWithHistory />}>
            <Route element={<AnalyticsProvider />}>
                <Route element={<ReportProvider />}>
                    <Route element={<ProtectedRoute />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/dashboards" element={<Navigate to="/dashboards/all" replace />} />
                        <Route
                            path="/dashboards/:tab"
                            loader={({ params }) => params.tab}
                            handle={{
                                crumb: ({ pathname, data }): BreadCrumbItem => {
                                    return {
                                        label: `Dashboards - ${data}`,
                                        url: pathname,
                                    };
                                },
                            }}
                        >
                            <Route index element={<Reports isDashboard />} />
                            <Route
                                path=":id"
                                element={<Report isDashboard />}
                                loader={({ params }) => <ReportName reportId={params.id} />}
                                handle={{
                                    crumb: ({ pathname, data }): BreadCrumbItem => {
                                        return {
                                            label: data,
                                            url: pathname,
                                        };
                                    },
                                }}
                            />
                        </Route>
                        <Route path="/reports" element={<Navigate to="/reports/all" replace />} />
                        <Route
                            path="/reports/:tab"
                            loader={({ params }) => params.tab}
                            handle={{
                                crumb: ({ pathname, data }): BreadCrumbItem => {
                                    return {
                                        label: `Reports - ${data}`,
                                        url: pathname,
                                    };
                                },
                            }}
                        >
                            <Route index element={<Reports />} />
                            <Route
                                path=":id"
                                element={<Report />}
                                loader={({ params }) => <ReportName reportId={params.id} />}
                                handle={{
                                    crumb: ({ pathname, data }): BreadCrumbItem => {
                                        return {
                                            label: data,
                                            url: pathname,
                                        };
                                    },
                                }}
                            />
                        </Route>
                        <Route
                            path="/subscriptions"
                            element={<Subscriptions />}
                            handle={{
                                crumb: ({ pathname }): BreadCrumbItem => {
                                    return {
                                        label: 'Subscriptions',
                                        url: pathname,
                                    };
                                },
                            }}
                        />
                        <Route
                            path="/configuration"
                            handle={{
                                crumb: ({ pathname }): BreadCrumbItem => {
                                    return {
                                        label: 'Configuration',
                                        url: pathname,
                                    };
                                },
                                permission: 'View Configuration',
                            }}
                        >
                            <Route index element={<Configuration />} />
                            <Route
                                path="categorization"
                                handle={{
                                    crumb: ({ pathname }): BreadCrumbItem => {
                                        return {
                                            label: 'Categorization',
                                            url: pathname,
                                        };
                                    },
                                }}
                            >
                                <Route index element={<Categorization />} />
                                <Route
                                    path=":categorizationId"
                                    loader={({ params }) => <CategorizationName categorizationId={params.categorizationId} />}
                                    handle={{
                                        crumb: ({ pathname, data }): BreadCrumbItem => {
                                            return {
                                                label: data,
                                                url: pathname,
                                            };
                                        },
                                    }}
                                >
                                    <Route index element={<Categories />} />
                                    <Route
                                        path="mapping/:glCodeSetId"
                                        element={<CategorizationMapping />}
                                        handle={{
                                            crumb: ({ pathname }): BreadCrumbItem => {
                                                return {
                                                    label: 'Mapping',
                                                    url: pathname,
                                                };
                                            },
                                        }}
                                    />
                                </Route>
                            </Route>
                            <Route
                                path="glCodeSets"
                                handle={{
                                    crumb: ({ pathname }): BreadCrumbItem => {
                                        return {
                                            label: 'GL Code Sets',
                                            url: pathname,
                                        };
                                    },
                                }}
                            >
                                <Route index element={<GLCodeSets />} />
                                <Route
                                    path=":glCodeSetId"
                                    element={<GLCodeSet />}
                                    loader={({ params }) => <GLCodeSetName glCodeSetId={params.glCodeSetId} />}
                                    handle={{
                                        crumb: ({ pathname, data }): BreadCrumbItem => {
                                            return {
                                                label: data,
                                                url: pathname,
                                            };
                                        },
                                    }}
                                />
                            </Route>
                        </Route>
                        <Route path="/budgets" element={<Navigate to="/budgets/occupancy" replace />} />
                        <Route
                            path="/budgets/:categoryName"
                            handle={{
                                crumb: ({ pathname }): BreadCrumbItem => {
                                    return {
                                        label: 'Budgets',
                                        url: pathname,
                                    };
                                },
                                permission: 'Edit Budgets',
                            }}
                        >
                            <Route index element={<Budgets />} />
                            <Route
                                path="/budgets/:categoryName/:budgetId/versions/:budgetVersionId"
                                element={<BudgetVersionEditor />}
                                handle={{
                                    crumb: ({ pathname }): BreadCrumbItem => {
                                        return {
                                            label: 'Editor',
                                            url: pathname,
                                        };
                                    },
                                    permission: 'Edit Budgets',
                                }}
                            />
                        </Route>
                        <Route
                            path="/admin"
                            handle={{
                                crumb: ({ pathname }): BreadCrumbItem => {
                                    return {
                                        label: `Admin`,
                                        url: pathname,
                                    };
                                },
                                permission: 'View Admin',
                            }}
                        >
                            <Route index element={<Admin />} />
                            <Route
                                path="/admin/users/:tab?"
                                loader={({ params }) => params.tab ?? null}
                                element={<UserConfig />}
                                handle={{
                                    crumb: ({ pathname, data }): BreadCrumbItem => {
                                        return {
                                            label: data,
                                            url: pathname,
                                        };
                                    },
                                    permission: 'View Users',
                                }}
                            />
                            <Route
                                path="/admin/edit/:tab"
                                element={<CompanyReportConfig />}
                                handle={{
                                    crumb: ({ pathname }): BreadCrumbItem => {
                                        return {
                                            label: 'Reports & Dashboards',
                                            url: pathname,
                                        };
                                    },
                                    permission: 'View Admin Reports/Dashboards',
                                }}
                            />
                            <Route
                                path="/admin/communities"
                                handle={{
                                    crumb: ({ pathname }): BreadCrumbItem => {
                                        return {
                                            label: 'Communities',
                                            url: pathname,
                                        };
                                    },
                                }}
                            >
                                <Route index element={<Communities />} />
                                <Route
                                    path="/admin/communities/:communityId/:tab"
                                    loader={({ params }) => <CommunityName communityId={params.communityId} />}
                                    element={<CommunityPage />}
                                    handle={{
                                        crumb: ({ pathname, data }): BreadCrumbItem => {
                                            return {
                                                label: data,
                                                url: pathname,
                                            };
                                        },
                                    }}
                                />
                            </Route>
                        </Route>
                        <Route
                            path="/sladmin"
                            handle={{
                                crumb: ({ pathname }): BreadCrumbItem => {
                                    return {
                                        label: `SLAdmin`,
                                        url: pathname,
                                    };
                                },
                                permission: 'View SLAdmin',
                            }}
                        >
                            <Route index element={<SLAdmin />} />
                            <Route
                                path="reports"
                                element={<ReportConfig />}
                                handle={{
                                    crumb: ({ pathname }): BreadCrumbItem => {
                                        return {
                                            label: 'Reports & Dashboards',
                                            url: pathname,
                                        };
                                    },
                                }}
                            />
                        </Route>
                    </Route>
                </Route>
            </Route>
            ,
        </Route>,
    ),
);

const App = () => <RouterProvider router={router} />;

export default App;
